import React from "react"
import { graphql } from 'gatsby'

import Layout from '../components/ui/layout'
import postListLdjson from '../components/ui/post-list-ldjson'
import Seo from '../components/ui/seo'
import Recommend from '../components/ui/recommend'
import NewWorks from '../components/ui/new-works'
import NewWorksNav from '../components/ui/new-works-nav'
import PostList from '../components/ui/post-list-tag'
import Nav from '../components/ui/post-list-nav'



const Tags = ({ pageContext: { tag }, data, location }) => {
  const pageTitle = `tag: ${tag}`
  const pageDescription = data.site.siteMetadata.tags.description ?
    data.site.siteMetadata.tags.description :
    `tag: ${tag} の一覧ページ`
  const postList = data.posts.edges
  const tags = data.tags
  const tagsMeta = data.site.siteMetadata.tags
  const catsMeta = data.site.siteMetadata.categories
  return (
    <Layout>
      <Seo
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageUrl={location.pathname}
        postList={postListLdjson({
          postList: postList,
          catsMeta: catsMeta,
        })}
      />
      <main>
        <Recommend className="pt-48" />
        <NewWorks/>
        <NewWorksNav className="mt-12" />
        <PostList
          className="pt-48"
          tag={tag}
          header={pageTitle}
          postList={postList}
          tags={tags}
          tagsMeta={tagsMeta}
          catsMeta={catsMeta}
          isH1={true}
        />
        <Nav className="mt-12" />
      </main>
    </Layout>
  );
}



export const query = graphql`
  query($tag: String) {
    posts: allMdx(
      sort: {fields: frontmatter___datePublished, order: DESC},
      filter: {frontmatter: {
        draft: {eq: false},
        tags: {in: [$tag]}
      }}
    ) {
      edges {
        node {
          slug
          frontmatter {
            tags
            draft
            eyecatch {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  aspectRatio: 2
                  width: 312
                )
              }
            }
            dateModified(formatString: "MMMM Do, YYYY")
            title
          }
        }
      }
    }
    tags: allMdx(filter: {frontmatter: {draft: {eq: false}}}) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    site {
      siteMetadata {
        tags {
          label
          slug
          description
        }
        categories {
          label
          slug
        }
      }
    }
  }
`

export default Tags
